import { makeStyles } from "@material-ui/core/styles"
import SearchIcon from "@material-ui/icons/Search"
import React, { FC } from "react"
import Theme from "src/entity/theme/theme"

const useStyles = makeStyles(() => ({
    search: {
        display: "flex",
        justifyContent: "center",
        height: "40px",
    },
    searchBar: {
        display: "flex",
        padding: "0px 10px",
        border: "1px solid lightgray",
        alignItems: "center",
        borderRadius: "4px",
    },
    searchBarInput: {
        border: "none !important",
        width: "120px",
        "&:focus": {
            outlineWidth: 0,
        },
        "&:placeholder": {
            textAlign: "center",
            fontSize: "15px",
            fontFamily: Theme.font.fontFamily,
        },
    },
}))

type SearchBoxProps = {
    placeholder: string
    searchText: string | number
    setSearchText: (v: string | number) => void
}

/**
 * 検索ボックス
 * @param placeholder プレースホルダーに表示させる名前
 * @param searchText vauleを変化させるstate
 * @param setSearchText searchTextを更新するstate関数
 */

const SearchBox: FC<SearchBoxProps> = (props) => {
    const classes = useStyles()
    const { placeholder, searchText, setSearchText } = props

    const changeText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value)
    }
    return (
        <div className={classes.search}>
            <div className={classes.searchBar}>
                <SearchIcon />
                <input
                    className={classes.searchBarInput}
                    placeholder={placeholder}
                    value={searchText}
                    onChange={changeText}
                />
            </div>
        </div>
    )
}
export default SearchBox
