import Snackbar from "@material-ui/core/Snackbar"
import { makeStyles } from "@material-ui/core/styles"
import MuiAlert from "@material-ui/lab/Alert"
import React from "react"

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}))

export default function CustomizedSnackbar(props) {
    const classes = useStyles()
    const { snackBar, setSnackBar } = props

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setSnackBar([false, ""])
    }

    return (
        <div className={classes.root}>
            <Snackbar open={snackBar[0]} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {snackBar[1]}
                </Alert>
            </Snackbar>
        </div>
    )
}
