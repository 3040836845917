import Avatar from "@material-ui/core/Avatar"
import Checkbox from "@material-ui/core/Checkbox"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import { green, blue, red } from "@material-ui/core/colors"
import { makeStyles } from "@material-ui/core/styles"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import React from "react"

import Theme from "../../../entity/theme/theme"
import CustomizedSnackbar from "../../atoms/customizedSnackbar"
import LinearProgress from "../../atoms/linearProgress"

const uesStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: "0px 10px",
        overflow: "auto",
        background: "#fff",
        paddingBottom: "120px",
        // height: "auto"
    },
    list: {
        flexGrow: 1,
    },
    heading: {
        fontSize: "14px",
        flexBasis: "70%",
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
    },
    date: {
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
        marginLeft: "auto",
        fontSize: "12px",
    },
    selectGrid: {
        flexBasis: "17%",
    },
    selectForm: {
        minWidth: theme.spacing(15),
        width: "100%",
    },
    select: {
        width: "120px",
    },
    avaterParent: {
        //flexBasis: "10%",
    },
    avatar: {
        width: "33px",
        height: "33px",
        fontSize: "1em",
        margin: "auto 0px",
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
    },
    emailGrid: {
        display: "flex",
        marginLeft: theme.spacing(1),
        flexBasis: "30%",
    },
    emailIcon: {
        marginRight: theme.spacing(1),
    },
    delete: {
        display: "flex",
        flexBasis: "25%",
    },
    cardRoot: {
        marginLeft: "9.5%",
        maxWidth: "81%",
        backgroundColor: green[50],
        flexGrow: 1,
        marginBottom: "20px",
    },
    cardContent: {
        maxHeight: theme.spacing(7),
        display: "flex",
        alignItems: "center",
    },
    checkIcon: {
        color: green[400],
        fontSize: "14px",
        flexBasis: "7%",
    },
    alertText: {
        fontSize: "14px",
        flexBasis: "90%",
    },
    typography: {
        padding: theme.spacing(2),
        fontFamily: Theme.font.fontFamily,
    },
    email: {
        fontSize: "12px",
    },
    setting: {
        display: "flex",
        flexDirection: "row",
        margin: "5px 0px",
    },
    settingTitle: {
        fontSize: "15px",
        fontWeight: "bold",
    },
    settingSubTitle: {
        margin: "15px",
        fontSize: "13px",
        fontWeight: "bold",
        fontFamily: Theme.font.fontFamily,
    },
}))

const searchCategories = {
    1: "承認待ち",
    2: "承認済み",
    3: "否承認",
}

const searchTypes = {
    member: "通常部員",
    leaders: "幹部部員",
    ob: "OB",
}

const searchTypesName = {
    member: "通常",
    leaders: "幹部",
    ob: "OB",
}
const searchCategoryColors = {
    1: green[600],
    2: blue[500],
    3: red[400],
}

export default function UserList(props) {
    const {
        visibleUserList,
        handleSelectStatus,
        handleSelectType,
        handleCheckDisplay,
        snackBar,
        setSnackBar,
        linearAnimation,
    } = props

    const classes = uesStyles()

    return (
        <div className={classes.root}>
            {visibleUserList.map((user, i) => {
                return (
                    <div key={user.id}>
                        {linearAnimation[i] && <LinearProgress />}
                        <ExpansionPanel
                            style={{ margin: "1px", overflowX: "hidden" }}
                            TransitionProps={{ unmountOnExit: true }}
                        >
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item className={classes.avaterParent}>
                                        {user.type && (
                                            <Avatar
                                                className={classes.avatar}
                                                style={{
                                                    backgroundColor:
                                                        searchCategoryColors[user.permission],
                                                }}
                                            >
                                                {searchTypesName[user.type]}
                                            </Avatar>
                                        )}
                                    </Grid>
                                    <Grid item className={classes.heading}>
                                        <div>{user.name}</div>
                                        {/* <div style={{ display: "flex", margin: "-18px 0px 18px" }}>
                                        <MailOutlineIcon className={classes.emailIcon} /> */}
                                        <div className={classes.email}>{user.email}</div>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails
                                style={{
                                    marginLeft: "40px",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <div className={classes.list}>
                                    <div claassName={classes.settingTitle}>基本設定</div>

                                    <div className={classes.setting}>
                                        <div className={classes.settingSubTitle}>ステータス</div>
                                        <div>
                                            <FormControl
                                                className={classes.selectForm}
                                                margin="dense"
                                            >
                                                <Select
                                                    labelId="select-permission"
                                                    id="select-permission"
                                                    value={user.permission}
                                                    onChange={handleSelectStatus(user.id, user, i)}
                                                    className={classes.select}
                                                >
                                                    {Object.keys(searchCategories).map((key) => (
                                                        <MenuItem value={key} key={key}>
                                                            <div>{searchCategories[key]}</div>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className={classes.setting}>
                                        <div className={classes.settingSubTitle}>役職</div>
                                        <div style={{ marginLeft: "40px" }}>
                                            <FormControl
                                                className={classes.selectForm}
                                                margin="dense"
                                            >
                                                <Select
                                                    labelId="select-permission"
                                                    id="select-permission"
                                                    value={user.type}
                                                    onChange={handleSelectType(user.id, user, i)}
                                                    className={classes.select}
                                                >
                                                    {Object.keys(searchTypes).map((key) => (
                                                        <MenuItem value={key} key={key}>
                                                            <div>{searchTypes[key]}</div>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className={classes.settingTitle}>表示設定</div>
                                    <div style={{ margin: "5px 35px 15px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={user.display === "true" ? true : false}
                                                    onChange={handleCheckDisplay(user.id, user, i)}
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <div className={classes.settingSubTitle}>
                                                    部員記録に反映させる
                                                </div>
                                            }
                                        />
                                        <div style={{ fontSize: "12px", color: "grey" }}>
                                            *offにすると他の通常部員からこのユーザーの記録が見えなくなります
                                        </div>
                                    </div>
                                </div>
                                <Grid item className={classes.date}>
                                    <div>登録日：{user.create_date}</div>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <CustomizedSnackbar setSnackBar={setSnackBar} snackBar={snackBar} />
                    </div>
                )
            })}
        </div>
    )
}
