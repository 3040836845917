import Backdrop from "@material-ui/core/Backdrop"
import Card from "@material-ui/core/Card"
import Checkbox from "@material-ui/core/Checkbox"
import Fade from "@material-ui/core/Fade"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import IconButton from "@material-ui/core/IconButton"
import MenuItem from "@material-ui/core/MenuItem"
import Popper from "@material-ui/core/Popper"
import Select from "@material-ui/core/Select"
import Typography from "@material-ui/core/Typography"
import { green, blue, red } from "@material-ui/core/colors"
import { makeStyles } from "@material-ui/core/styles"
import ContactSupportIcon from "@material-ui/icons/ContactSupport"
import React from "react"

import Theme from "../../../entity/theme/theme"

import SearchBox from "@/presentation/atoms/searchBox"

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0px 10px",
        margin: "0px 4px 0px 4px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        fontFamily: Theme.font.fontFamily,
    },
    input: {
        //marginBottom: theme.spacing(-1.75),
        marginLeft: "auto",
        width: "105px",
        marginTop: "20px",
        marginRight: "4px",
    },
    divider: {
        height: 28,
        margin: 4,
        marginBottom: theme.spacing(-3),
    },
    select: {
        width: "145px",
        marginRight: "auto",
        marginTop: "6px",
        //marginBottom: theme.spacing(-3),
    },
    font: {
        fontFamily: Theme.font.fontFamily,
        fontWeight: "400",
    },
    typography: {
        padding: theme.spacing(2),
        fontFamily: Theme.font.fontFamily,
    },
}))

export default function SearchUser(props) {
    const classes = useStyles()
    const {
        loginUser,
        checked,
        handleCheck,
        searchText,
        setSearchText,
        selectedCategory,
        handleSelect,
    } = props
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [placement, setPlacement] = React.useState()
    const [open, setOpen] = React.useState([false, false])

    const handleClick = (newPlacement, i) => (event) => {
        setAnchorEl(event.currentTarget)
        let op = [...open]
        op[i] = !op[i]
        setOpen(op)
        setPlacement(newPlacement)
    }

    return (
        <div className={classes.root}>
            <Backdrop
                open={open[0]}
                onClick={() => {
                    setOpen([false, false])
                }}
                style={{ zIndex: 8 }}
            />
            <Backdrop
                open={open[1]}
                onClick={() => {
                    setOpen([false, false])
                }}
                style={{ zIndex: 8 }}
            />

            <Popper
                open={open[0]}
                anchorEl={anchorEl}
                placement={placement}
                transition
                style={{ zIndex: 9, width: "80%" }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        {/* <Paper> */}
                        <Card className={classes.typography}>
                            <span style={{ fontWeight: "bold" }}>承認待ちのユーザーは、</span>
                            承認されるまで、{loginUser.team}
                            にログインできず、部員として反映されません。
                            <br />
                            <br />
                            <span style={{ fontWeight: "bold" }}>承認済みのユーザーは、</span>
                            部員として承認され、{loginUser.team}
                            にログインでき、部員として反映されます。
                            <br />
                            <br />
                            <span style={{ fontWeight: "bold" }}>非承認のユーザーは、</span>
                            {loginUser.team}にログインできず、部員として反映されません。
                            <br />
                            <br />
                            <span style={{ fontWeight: "bold" }}>＊推奨事項</span>
                            <br />
                            <span style={{ color: "red" }}>
                                知らないユーザーは、非承認にしてください。
                                <br />
                                また、重複したユーザーがいる場合、どちらかを非承認にしてください。重複していると一部正しいデータを表示できない可能性があります。
                            </span>
                        </Card>
                    </Fade>
                )}
            </Popper>
            <Popper
                open={open[1]}
                anchorEl={anchorEl}
                placement={placement}
                transition
                style={{ zIndex: "301", width: "80%" }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        {/* <Paper> */}
                        <Card className={classes.typography}>
                            <span style={{ fontWeight: "bold" }}>通常部員のユーザーは、</span>
                            幹部部員の機能を使用することができません。
                            <br />
                            <br />
                            <span style={{ fontWeight: "bold" }}>幹部部員のユーザーは、</span>
                            幹部部員は、{loginUser.team}
                            の部員の利用権限や役職を変更することができます。また、欠席遅刻連絡のメールも受け取れます。
                            <br />
                            <br />
                            <span style={{ fontWeight: "bold" }}>OBのユーザーは、</span>
                            部員記録は見えますが、全ての部員記録、ランキングにOBの記録が表示されなくなります。
                            <br />
                            <br />
                            <span style={{ fontWeight: "bold" }}>＊推奨事項</span>
                            <br />
                            <span style={{ color: "red" }}>
                                代替わりをした際は、引退した部員をOBに変更してください。部員記録や部員プロフィール一覧に反映されないようになります。
                                <br />
                                また、自身の役職を変更を変更する際は、次の幹部部員を任命してから、自身の役職を変更してください。
                            </span>
                        </Card>
                        {/* </Paper> */}
                    </Fade>
                )}
            </Popper>

            <div>
                <div style={{ fontSize: "18px" }}>
                    役職
                    <IconButton
                        style={{ color: "grey", top: "-9px", padding: "0px" }}
                        onClick={handleClick("bottom-start", 1)}
                    >
                        <ContactSupportIcon style={{ fontSize: "smaller" }} />
                    </IconButton>
                </div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked.member}
                            onChange={handleCheck("member")}
                            value="member"
                            color="primary"
                        />
                    }
                    label={<div className={classes.font}>通常部員</div>}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked.leaders}
                            onChange={handleCheck("leaders")}
                            value="leaders"
                            color="primary"
                        />
                    }
                    label={<div className={classes.font}>幹部部員</div>}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked.ob}
                            onChange={handleCheck("ob")}
                            value="ob"
                            color="primary"
                        />
                    }
                    label={<div className={classes.font}>OB</div>}
                />
            </div>

            <div style={{ display: "flex", marginTop: "6px", alignItems: "end" }}>
                <div style={{ width: "70%" }}>
                    <div style={{ fontSize: "18px" }}>
                        ステータス
                        <IconButton
                            style={{ color: "grey", top: "-9px", padding: "0px" }}
                            onClick={handleClick("bottom-start", 0)}
                        >
                            <ContactSupportIcon style={{ fontSize: "smaller" }} />
                        </IconButton>
                    </div>
                    <Select
                        labelId="select-category"
                        id="select-category"
                        value={selectedCategory}
                        onChange={handleSelect}
                        className={classes.select}
                    >
                        <MenuItem value={0}>
                            <Typography variant="button">
                                <span style={{ color: green[600] }}>承認待ち</span>/
                                <span style={{ color: blue[500] }}>承認済み</span>
                            </Typography>
                        </MenuItem>
                        <MenuItem value={1}>
                            <Typography variant="button" style={{ color: red[400] }}>
                                非承認
                            </Typography>
                        </MenuItem>
                    </Select>
                </div>
                <div style={{ margin: "auto 4px 0px auto" }}>
                    <SearchBox
                        placeholder={"名前,メール"}
                        searchText={searchText}
                        setSearchText={setSearchText}
                    />
                </div>
            </div>
        </div>
    )
}
