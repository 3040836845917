import { makeStyles } from "@material-ui/core/styles"
import { navigate } from "gatsby"
import moment from "moment"
import React from "react"
import styled from "styled-components"
import swal from "sweetalert"

import swalErrorAction from "../entity/alert/swalErrorAction"
import {
    networkError,
    typeChangeError,
    permissionChangeError,
    mychangeLogout,
} from "../entity/alert/swalErrorList"
import Theme from "../entity/theme/theme"
import BoxLoading from "../presentation/atoms/boxLoading"
import SearchUser from "../presentation/organisms/admin/searchUser"
import UserList from "../presentation/organisms/admin/userList"
import { adminRepository, userRepository } from "../repository/action"

import FrameTemplate from "@/presentation/templates/frameTemplate"

const useStyles = makeStyles((theme) => ({
    root: {
        displey: "flex",
        // overflowY: "auto",
        // overflowX: "hidden",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
        background: "#fff",
    },
    base: {
        maxWidth: theme.spacing(100),
        backgroundColor: "white",
    },
    title: {
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
        fontSize: "20px",
        margin: "6px 1px",
    },
}))

export const searchCategories = {
    0: "承認待ち/承認済み",
    1: "否承認",
}

const categories = {
    1: "承認待ち",
    2: "承認済み",
    3: "否承認",
}
export const searchTypes = {
    member: "通常部員",
    leaders: "幹部部員",
    ob: "OB",
}

export default function Admin(props) {
    const classes = useStyles()
    const [loginUser, setLoginUser] = React.useState()
    const [rangeDate, setRangeDate] = React.useState({
        startDate: moment().startOf("month"),
        endDate: moment().endOf("month"),
    })
    const [title, setTitle] = React.useState("ユーザー管理")
    const [memberData, setMemberData] = React.useState()
    const [isLoading, setIsLoading] = React.useState(false)
    const [category, setCategory] = React.useState(0)
    const [searchText, setSearchText] = React.useState("")
    const [visibleUserList, setVisibleUserList] = React.useState([])
    const [sortedUserList, setSortedUserList] = React.useState([])
    const [checked, setChecked] = React.useState({ member: true, leaders: true, ob: true })
    const [snackBar, setSnackBar] = React.useState([false, ""])
    const [notificationCount, setNotificationCount] = React.useState(0)
    const [linearAnimation, setLinearAnimation] = React.useState()

    const handleSelectCategory = (event) => {
        setCategory(event.target.value)
    }
    const linearAnimationChange = (i) => {
        let flag = linearAnimation
        flag[i] = !flag[i]
        setLinearAnimation(flag)
    }

    const handleSelectStatus = (id, user, i) => (event) => {
        let applove = [...visibleUserList].filter((v) => {
            if (v.permission == "2" && v.type == "leaders") {
                return v
            }
        })
        if (
            (event.target.value == "1" || event.target.value == "3") &&
            applove.length == 1 &&
            applove[0].id == id
        ) {
            swal(permissionChangeError)
        } else {
            linearAnimationChange(i)
            adminRepository
                .permissionChange(loginUser, id, event.target.value)
                .then(() => {
                    // メールを送信
                    adminRepository
                        .statusEmailSend(user.email, categories[event.target.value])
                        .then(() => {
                            setSnackBar([true, `${user.name}のステータスを変更しました`])
                            userRepository
                                .memberDataFetch(loginUser)
                                .then((data) => {
                                    setMemberData(data)
                                    setSortedUserList(data)
                                    if (loginUser.id == id) {
                                        linearAnimationChange(i)
                                        swalErrorAction(mychangeLogout)
                                    }
                                })
                                .catch(() => {
                                    swalErrorAction(networkError)
                                })
                        })
                        .catch(() => {
                            swalErrorAction(networkError)
                        })
                })
                .catch(() => {
                    swalErrorAction(networkError)
                })
        }
    }

    const handleSelectType = (id, user, i) => (event) => {
        let leaders = [...visibleUserList].filter((v) => {
            if (v.type == "leaders") {
                return v
            }
        })
        if (leaders.length == 1 && leaders[0].id == id) {
            swal(typeChangeError)
        } else {
            linearAnimationChange(i)
            adminRepository
                .typeChange(id, user.team, event.target.value)
                .then(() => {
                    // メールを送信
                    adminRepository
                        .typeEmailSend(user.email, searchTypes[event.target.value])
                        .then(() => {
                            setSnackBar([true, `${user.name}の役職を変更しました`])
                            userRepository
                                .memberDataFetch(loginUser)
                                .then((data) => {
                                    setMemberData(data)
                                    setSortedUserList(data)
                                    if (loginUser.id == id) {
                                        linearAnimationChange(i)
                                        swalErrorAction(mychangeLogout)
                                    }
                                })
                                .catch(() => {
                                    swalErrorAction(networkError)
                                })
                        })
                        .catch(() => {
                            swalErrorAction(networkError)
                        })
                })
                .catch(() => {
                    swalErrorAction(networkError)
                })
        }
    }

    const handleCheckDisplay = (id, user, i) => (event) => {
        linearAnimationChange(i)
        adminRepository
            .displayChange(id, user.team, String(event.target.checked))
            .then(() => {
                // メールを送信
                setSnackBar([true, `${user.name}の表示設定を変更しました`])
                userRepository
                    .memberDataFetch(loginUser)
                    .then((data) => {
                        setMemberData(data)
                        setSortedUserList(data)
                        if (loginUser.id == id) {
                            linearAnimationChange(i)
                            swalErrorAction(mychangeLogout)
                        }
                    })
                    .catch(() => {
                        swalErrorAction(networkError)
                    })
            })
            .catch(() => {
                swalErrorAction(networkError)
            })
    }

    const handleCheck = (name) => (event) => {
        setChecked({
            ...checked,
            [name]: event.target.checked,
        })
    }

    // 検索時の処理
    React.useEffect(() => {
        let visibleArray = sortedUserList.filter((element) => {
            let elementChecked
            if (!element.type) return -1
            if (element.type === "member") elementChecked = checked.member
            else if (element.type === "leaders") elementChecked = checked.leaders
            else if (element.type === "ob") elementChecked = checked.ob
            let categorySelected =
                category == 0
                    ? element.permission == "1" || element.permission == "2"
                    : element.permission == "3"
            return (
                elementChecked && //チェックボックス
                (element.name.indexOf(searchText) != -1 ||
                    element.email.indexOf(searchText) != -1) && // テキスト
                categorySelected // カテゴリ
            )
        })
        setVisibleUserList(visibleArray)
        setLinearAnimation(Array(visibleArray.length).fill(false))
    }, [category, searchText, checked, sortedUserList])

    // ページ更新時
    React.useEffect(() => {
        if (props.location.action) {
            if (props.location.state.loginUser) {
                setLoginUser(props.location.state.loginUser)
                let setUserjson = JSON.stringify(props.location.state.loginUser)
                localStorage.setItem(["loginUser"], [setUserjson])
            }
            if (props.location.state.memberData) {
                setMemberData(props.location.state.memberData)
                setSortedUserList(props.location.state.memberData)
            }
        } else {
            let getUserjson = localStorage.getItem(["loginUser"])
            if (getUserjson && JSON.parse(getUserjson).type == "leaders") {
                userRepository
                    .sessionLogin(JSON.parse(getUserjson).email, JSON.parse(getUserjson).password)
                    .then((data) => {
                        setLoginUser(data.user_info)
                        setMemberData(data.memberData)
                        setSortedUserList(data.memberData)
                    })
                    .catch(() => {
                        swalErrorAction(networkError)
                    })
            } else {
                navigate("/")
            }
        }
    }, [])

    React.useEffect(() => {
        if (sortedUserList) {
            let notification_count = 0
            sortedUserList.forEach((v) => {
                if (v.permission == "1") {
                    notification_count++
                }
            })
            setNotificationCount(notification_count)
        }
    }, [sortedUserList, snackBar])

    return (
        <div className={classes.root}>
            <FrameTemplate
                loginUser={loginUser}
                setLoginUser={setLoginUser}
                setIsLoading={setIsLoading}
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
                memberData={memberData}
                setMemberData={setMemberData}
                setTitle={setTitle}
                title={title}
                notificationCount={notificationCount}
            />
            <Contents>
                {!loginUser || !memberData || isLoading ? (
                    <BoxLoading />
                ) : (
                    <div
                        style={{
                            height: "100%",
                            background: "#fff",
                            padding: "52px 0px 100px 0px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "center",
                                color: "red",
                            }}
                        >
                            {notificationCount > 0 && (
                                <>承認待ちのユーザーが{notificationCount}人います</>
                            )}
                        </div>
                        <div>
                            <SearchUser
                                className={classes.search}
                                searchText={searchText}
                                setSearchText={setSearchText}
                                selectedCategory={category}
                                handleSelect={handleSelectCategory}
                                searchCategories={searchCategories}
                                types={searchTypes}
                                checked={checked}
                                handleCheck={handleCheck}
                                loginUser={loginUser}
                            />
                        </div>
                        <div>
                            <UserList
                                className={classes.menteeList}
                                visibleUserList={visibleUserList}
                                handleSelectStatus={handleSelectStatus}
                                handleSelectType={handleSelectType}
                                handleCheckDisplay={handleCheckDisplay}
                                snackBar={snackBar}
                                setSnackBar={setSnackBar}
                                linearAnimation={linearAnimation}
                            />
                        </div>
                    </div>
                )}
            </Contents>
        </div>
    )
}

const Contents = styled.div`
    height: 100vh;
    background: #fff;
`
